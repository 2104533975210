import React from "react";
import ReactDOM from "react-dom/client";

import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import axios from "axios";
import { ixicoTransformers } from "lib/common";
import Preload from "components/sign-in-loading/Preload";
import { addAuthEventCallback } from "lib/auth";

const App = React.lazy(() => import("./App"));

axios.defaults.transformRequest = ixicoTransformers();

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

addAuthEventCallback(msalInstance);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // StrictMode is disabled for now as this causes development-time issues with useEffect and associated code (e.g. queries and list navigation components).
  // To enable StrictMode without these issues would require some refactoring.
  // <React.StrictMode>
  <React.Suspense fallback={<Preload/>}>
    <App pca={msalInstance} />
  </React.Suspense>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// import reportWebVitals from './reportWebVitals';
// reportWebVitals();
