import { Grid, GridItem, Box, Link, Text } from "@chakra-ui/layout";
import { ChakraProps, Image } from "@chakra-ui/react";
import { IChildren } from "lib/common/interfaces";
import React, { PropsWithChildren } from "react";
import { LogoFull, Loader } from "../../styles/icons";
import { ISignInLayoutProps, ISignInLinkProps } from "./interfaces";

const CardContainer: React.FC<IChildren> = ({ children }) => {
  return (
    <Box
      width="420px"
      height="480px"
      padding="42px"
      position="absolute"
      right="-100px"
      top="60px"
      background="white"
      boxShadow="0px 4px 4px rgba(203, 203, 203, 0.25)"
      borderRadius="10px"
      textAlign="left"
      opacity={"0.85"}
    >
      { children }
    </Box>
  );
};

const LogoContainer: React.FC<IChildren> = ({ children }) => {
  return (
    <Box marginBottom="30px">
      { children }
    </Box>
  );
};

const Title: React.FC<PropsWithChildren<ChakraProps>> = ({ children, ...rest }) => {
  return (
    <Box
      as="h1"
      marginBottom="10px"
      fontSize="24px"
      fontWeight="bold"
      {...rest}
    >
      { children }
    </Box>
  );
};

export const SubTitleContent: React.FC<IChildren> = ({ children }) => {
  return (
    <Text textStyle="cardBody">{ children }</Text>
  );
};

const SubTitle: React.FC<PropsWithChildren<ChakraProps>> = ({ children, ...rest }) => {
  return (
    <Box marginBottom="10px" {...rest}>
      {children}
    </Box>
  );
};

export const SignInContent: React.FC<PropsWithChildren<ISignInLayoutProps>> = ({ children, title, subTitle, titleTestId, subTitleTestId }) => {
  return (
    <>
      <Title data-testid={titleTestId}>{title}</Title>
      <SubTitle data-testid={subTitleTestId}>{subTitle}</SubTitle>
      {children}
    </>
  );
};

export const SignInLink: React.FC<PropsWithChildren<ISignInLinkProps>> = ({ children, href, ...rest }) => {
  return (
    <Link
      href={href}
      marginTop="13px"
      color="#2da0ae"
      textDecor="underline"
      fontWeight="600"
      {...rest}
    >
      { children }
    </Link>
  );
};

const LoadingContainer: React.FC<IChildren> = ({ children }) => {
  return (
    <Box
      textAlign="center"
      marginTop="40px"
      data-testid="loading-container"
    >
      { children }
    </Box>
  );
};

export const LoadingProgress: React.FC = React.memo(() => {
  return (
    <LoadingContainer>
      <Loader width="100px" height="100px" />
    </LoadingContainer>
  );
}, () => true);

export const SignInLayout: React.FC<IChildren> = ({ children }) => {
  return (
    <Grid
      data-testid="sign-in-layout"
      width="100vw"
      height="100vh"
      minWidth="1200px"
      minHeight="700px"
      justifyContent="center"
      alignContent="center"
      overflowY="auto"
      bg="#F2F8F9"
    >
      <GridItem>
        <Box width="700px" height="600px" position="relative">
          <Box position="absolute" left="-100px" top="40px">
            <Image src="/images/IxiIcon_small.webp" alt="" height="528px" width="auto" filter={"opacity(0.6)"}/>
          </Box>
          <CardContainer>
            <LogoContainer>
              <LogoFull width="150px" height="50px" />
            </LogoContainer>
            { children }
          </CardContainer>
        </Box>
      </GridItem>
    </Grid>
  );
};
